import React, { useState, useEffect } from 'react';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/AuthContext';
import userService from '../../services/UserService';
import MyButton from '../../UI/button/MyButton';
import MyTextarea from '../../UI/textarea/MyTextarea';

export default function SubUserAdditionForm() {
  const [emails, setEmails] = useState(''); 
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { authUser } = useAuth();
  
  const remainingSlots = authUser.maxSubUsers - authUser.subUsersCount;
  const handleEmailChange = (e) => {
    const input = e.target.value;
    const emailList = input.split(/[\s,]+/).map(email => email.trim()).filter(email => email);

    // Allow input only if the number of emails doesn't exceed the limit
    if (emailList.length <= remainingSlots) {
      setEmails(input);
    } else {
      toast.error(`Poți adăuga doar ${remainingSlots} subconturi.`, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const emailList = emails.split(/[\s,]+/).map(email => email.trim()).filter(email => email);
      if (emailList.length === 0) {
        throw new Error('Please enter at least one valid email.');
      }
      await userService.addSubUser(emailList);
      toast.success("Cererea a fost transmisa cu succes.", {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setEmails(''); 
    } catch (error) {
      setError(error.message);
      if (error.response && error.response.status === 409) {
        toast.error("Unele subconturi sunt deja legate de acest cont.", {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("A apărut o eroare la trimiterea cererii, încercați din nou mai târziu.", {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className='section-form-container' onSubmit={handleSubmit}>
      <div className='section-top-flex'>
        <div className='section-title-info'>
          <p className='section-description'>Folosește formularul de mai jos pentru a adăuga subconturi. Introdu adresele de email separate prin virgulă sau pe linii separate.</p>
        </div>
        <div className='section-form-button'>
          <MyButton body="Trimite cerere" />
        </div>
      </div>
      <div className="spacer-m-2"></div>
      <div className='section-text'>
        <div className='section-row'>
          <MyTextarea
            name="subUserEmails"
            value={emails}
            onChange={handleEmailChange}
            isRequired={true}
            placeholder="Introdu email-urile aici, separate prin virgulă sau pe linii diferite"
            disabled={remainingSlots <= 0}
          />
        </div>
        <div className="description">Subconturile vor primi o cerere de confirmare pe email.
        <span className="subUsersCounter">
          <strong>{authUser.subUsersCount}</strong> / {authUser.maxSubUsers} 
        </span>
        </div>
        <CircleSpinnerOverlay loading={loading} overlayColor="rgba(0, 0, 0, 0.8)" color='#1b1b1bff' />
      </div>
    </form>
  );
}
