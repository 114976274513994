import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import MyButton from '../UI/button/MyButton';
import MyInput from '../UI/input/MyInput';

export default function ({ goToSubscriptionDetails, planId, setPlanId, currentPlanId, updatePlan,subscriptionStatus, goBack,setThirdStep,
                            maxActiveSessions,setMaxActiveSessions,termsAccepted,setTermsAccepted }) {
    const monthlyPrice = 300;
    const semestrialPrice =1350;
    const annualPrice = 2520;
    const [finalMonthlyPrice,setFinalMonthlyPrice] = useState(300);
    const [finalSemestrialPrice,setFinalSemestrialPrice] = useState(1350);
    const [finalAnnualPrice,setFinalAnnualPrice] = useState(2520);
    const {authUser} = useAuth();
   
    const onSubmit = async() =>{
        if(!validation()){
            return;
        }
        setThirdStep(true);
    } 
    function validation(){
        if (!planId) {
            toast.error('Selectați un plan înainte de plată.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false;
        }
        if(planId === currentPlanId && subscriptionStatus === "active" && authUser.subscription.paymentMethod === "maib"){
            toast.error('Abonamentul selectat deja este activ.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false;
        }
        if(!termsAccepted){
            toast.error('Acceptați Termenii și condițiile.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        return true;
    }
    useEffect(()=>{
        if (authUser){
        setTermsAccepted(authUser.termsAccepted);
        }
    },[authUser])
    

    useEffect(()=>{
        let i = 1;
        let sum = 0;
        let sum6 = 0;
        let sum12 = 0;
        while(i <= maxActiveSessions){
            if(i < 6 &&  i > 3){
                sum+=50;
                sum6+=50*3;
                sum12+=50*6;
            }else if(i < 11 &&  i > 5){
                sum+=45;
                sum6+=45*3;
                sum12+=45*6;
            }else if(i > 10){
                sum+=40;
                sum6+=40*3;
                sum12+=40*6;
            }
            i++;
        }
        setFinalMonthlyPrice(monthlyPrice + sum);
        setFinalSemestrialPrice(semestrialPrice + sum6);
        setFinalAnnualPrice(annualPrice + sum12);
    },[maxActiveSessions])



  return (
    <div>
        <div>
        <div className='payment-label'><h2>Abonamente</h2></div>
    <div className='bundles-wrapper'>
        <MyButton 
        body = {<><h3>Semestrial</h3><hr /><h2> {finalSemestrialPrice} lei </h2><p>Acces: 6 Luni </p><p>Economisești <b>25%</b></p><h4>{finalSemestrialPrice/6} lei/lună</h4><span>ALEGE</span></>}
        type="button"
        className={planId === "sem80" ? "bundle-card selected" : "bundle-card sem"}
        onClick ={() => planId === "sem80" ? setPlanId(null) : setPlanId("sem80") }
        />
        <MyButton 
        body = {<><h3>Anual</h3><hr /><h2> {finalAnnualPrice} lei </h2><p>Acces: 1 an</p><p>Economisești <b>30%</b></p><h4>{finalAnnualPrice/12} lei/lună</h4><span>ALEGE</span></>}
        type="button"
        className={planId === "anu150" ? "bundle-card selected" : "bundle-card an"}
        onClick ={() => planId === "anu150" ? setPlanId(null) : setPlanId("anu150") }
        />
        <MyButton 
        body = {<><h3>Lunar</h3><hr /><h2> {finalMonthlyPrice} lei </h2><p>Acces: 1 Lună </p><p>Economisești <b>0%</b></p><h4>{finalMonthlyPrice} lei/lună</h4><span>ALEGE</span></>}
        type="button"
        className={planId === "lun20" ? "bundle-card selected" : "bundle-card lun"}
        onClick ={() => planId === "lun20" ? setPlanId(null) : setPlanId("lun20") }
        />
    </div>
    <div className="max-active-sessions-container">
           <br />
            <input 
                type="range" 
                id="maxActiveSessions" 
                min={3} 
                max={20} 
                value={maxActiveSessions} 
                onChange={(e) => setMaxActiveSessions(parseInt(e.target.value))}
                className="max-active-sessions-slider"
            />
            <label htmlFor="maxActiveSessions"><h3>Utilizatori activi: {maxActiveSessions}</h3></label><br />
        </div>
        {
        authUser && !authUser.termsAccepted && <div className='terms-confirmation'>
        <MyInput
                name="terms-check"
                type="checkbox"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                label="Accept"
            /><a className="aterms" href='/termeni-conditii' target='_blank'>Termeni și condiții</a>
            </div>
    }
            </div>
            <div>
           <MyButton 
            onClick={onSubmit}
            className = "button-with-loader"
            body={updatePlan
             ? authUser.subscription.paymentMethod === "maib" ? "Schimbă" : "Prelungește" : "Achită"}
            />
             {updatePlan && <MyButton 
                    body = "Înapoi"
                    type = "button"
                    className="cancel-button"
                    onClick = {goToSubscriptionDetails}
                />}
                {goBack && !updatePlan && <MyButton 
                    body = "Înapoi"
                    type = "button"
                    className="cancel-button"
                    onClick = {goBack}
                />}
           </div>
    </div>
  )
}
